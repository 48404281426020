import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.tomSelect = new TomSelect(this.element, {
      plugins: ['remove_button'],
      create: false,
      preload: true,
      valueField: 'id',
      labelField: 'name',
      searchField: ['first_name', 'last_name', 'email'],
      load: (query, callback) => {
        const url = `${this.element.dataset.sourceUrl}?query=${encodeURIComponent(query)}`
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json)
          }).catch(() => {
            callback()
          })
      },
      render: {
        no_results: function (data, escape) {
          return '';
        },
        item: function (item, escape) {
          if (item.name) {
            return '<div>' +
              '<span>' + escape(item.name) + '</span>' +
              '</div>';
          }

          const name = ((item.first_name || '') + ' ' + (item.last_name || '')).trim();

          return '<div>' +
            (name ? '<span>' + escape(name) + '</span>' : '') +
            '<span class="opacity-70">&nbsp;(' + escape(item.email) + ')</span>' +
            '</div>';
        },
        option: function (item, escape) {
          const name = ((item.first_name || '') + ' ' + (item.last_name || '')).trim();
          const label = name || item.email;
          const caption = name ? item.email : null;

          return '<div>' +
            '<span class="text-sm">' + escape(label) + '</span>' +
            (caption ? '<span class="text-xs block text-gray-500">' + escape(caption) + '</span>' : '') +
            '</div>';
        }
      }
    })
  }

  disconnect() {
    this.tomSelect.destroy()
  }
}
